<template>
  <v-row justify="center">
    <v-dialog v-model="shower" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card :color="color.BG">
        <v-toolbar :color="color.theme" :dark="color.darkTheme" :text-color="color.chipText">
          <v-toolbar-title>B2P</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="$emit('closedialog')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <v-overlay :value="loaddataprogress" absolute dark>
            <v-progress-circular indeterminate size="64" color="#263238"></v-progress-circular>
            <br />
            <span style="color:#263238">&nbsp; &nbsp; loading</span>
          </v-overlay>
          <v-card-text class="pa-0" style="height: 100vh">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height v-if="type_doc_resend === 'รายการไฟล์'">
                <v-flex lg12 xs12 class="mr-4 ml-4">
                  <v-expansion-panels v-model="panel" multiple outlined>
                    <v-expansion-panel class="pa-1">
                      <v-expansion-panel-header>
                        <span :style="headerPage">{{ $t("dcmPage.conditionFile") }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-1">
                        <v-form>
                          <v-layout row wrap fill-height justify-center>
                            <v-flex xs12 sm12 md5 lg5 class="pa-1">
                              <v-container fluid>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md12 lg12>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-0 pt-0 pb-0 pl-0"
                                      v-model="file_namedoc"
                                      :label="$t('myinboxPage.conditionOptional.filename')"
                                      prepend-icon="insert_drive_file"
                                      :color="color.theme"
                                    ></v-text-field>
                                  </v-flex>
                                  <!-- เรียงข้อมูล -->
                                  <!-- <v-flex xs12 sm12 md6 lg6>
                                    <v-select
                                      outlined
                                      dense
                                      clearable
                                      class="pa-0 pt-0 pb-0 pl-1"
                                      v-model="sortby_data"
                                      :items="listdsortby_data"
                                      label="เรียงข้อมูล"
                                      prepend-icon="mdi-sort"
                                      :color="color.theme"
                                      :item-color="color.theme"
                                    ></v-select>
                                  </v-flex> -->
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <!-- ช่องวันที่อัป -->
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-dialog
                                      ref="dialogFromdateupload"
                                      v-model="modalFromdateupload"
                                      :return-value.sync="fromdateupload"
                                      persistent
                                      full-width
                                      width="290px"
                                      :color="color.theme"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          clearable
                                          :color="color.theme"
                                          :label="$t('myinboxPage.conditionOptional.fromdateupload')"
                                          v-model="fromdateupload"
                                          @click:prepend="modalFromdateupload = true"
                                          prepend-icon="event"
                                          v-on="on"
                                          readonly
                                          outlined
                                          dense
                                          @click:clear="todateupload = ''"
                                          class="my-hint-style pa-0 pt-0 pb-0"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="fromdateupload"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="changeFromdateupload()"
                                        :color="color.theme"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn dark color="error" @click.stop="modalFromdateupload = false">{{
                                          $t("myinboxPage.conditionOptional.closefromdateupload")
                                        }}</v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                  <!-- ถึง -->
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-dialog
                                      ref="dialogTodateupload"
                                      v-model="modalTodateupload"
                                      :return-value.sync="todateupload"
                                      persistent
                                      full-width
                                      width="290px"
                                      :color="color.theme"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          clearable
                                          readonly
                                          :color="color.theme"
                                          :label="$t('myinboxPage.conditionOptional.todateupload')"
                                          v-model="todateupload"
                                          prepend-icon="event"
                                          @click:prepend="modalTodateupload = true"
                                          v-on="on"
                                          outlined
                                          dense
                                          class="my-hint-style pa-0 pt-0 pb-0 pl-1"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="todateupload"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="$refs.dialogTodateupload.save(todateupload)"
                                        :min="fromdateupload"
                                        :color="color.theme"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn dark color="error" @click.stop="modalTodateupload = false">{{
                                          $t("myinboxPage.conditionOptional.closetodateupload")
                                        }}</v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <!-- ผู้รับ-->
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      class="pa-0 pt-0 pb-0 pl-0"
                                      v-model="receiver_name"
                                      :label="$t('myinboxPage.conditionOptional.receivername')"
                                      prepend-icon="account_box"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-select
                                      outlined
                                      dense
                                      class="pa-0 pt-0 pb-0 pl-1"
                                      v-model="document_type_code"
                                      :items="listdocument_type_code"
                                      item-text="document_type"
                                      item-value="document_type_code"
                                      :label="$t('myinboxPage.conditionOptional.fileextension')"
                                      prepend-icon="extension"
                                      :color="color.theme"
                                      :item-color="color.theme"
                                    ></v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <!-- ผู้ส่ง เปลี่ยนเป็น textfild-->
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      class="pa-0 pt-0 pb-0 pl-0"
                                      v-model="sender"
                                      :label="$t('myinboxPage.conditionOptional.sendername')"
                                      prepend-icon="account_circle"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-select
                                      outlined
                                      dense
                                      class="pa-0 pt-0 pb-0 pl-1"
                                      v-model="sendstatus"
                                      :items="listsendstatus"
                                      :label="$t('myinboxPage.conditionOptional.status')"
                                      prepend-icon="format_list_numbered"
                                      :color="color.theme"
                                      :item-color="color.theme"
                                    ></v-select>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-flex>

                            <v-flex  sm6 md1 lg1 class="text-center" v-if="resolutionScreen >= 500">
                              <v-divider vertical></v-divider>
                            </v-flex>

                            <v-flex xs12 sm12 md5 lg5 class="pa-1">
                              <v-container fluid>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documentid"
                                      :label="$t('myoutboxPage.conditionOptional.filedocumentid')"
                                      prepend-icon="format_list_numbered"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documentidto"
                                      :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                      prepend-icon="format_list_numbered"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <!-- ช่องกรอก เอกสารลงวันที่ -->
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-dialog
                                      ref="dialogFromdatedocument"
                                      v-model="modalFromdatedocument"
                                      :return-value.sync="fromdatedocument"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          :color="color.theme"
                                          :label="$t('myoutboxPage.conditionOptional.fromdatedocument')"
                                          v-model="fromdatedocument"
                                          @click:prepend="modalFromdatedocument = true"
                                          prepend-icon="event"
                                          v-on="on"
                                          readonly
                                          outlined
                                          dense
                                          clearable
                                          :persistent-hint="fromdate_hint"
                                          @click:clear="todatedocument = ''"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="fromdatedocument"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="changeFromdatedocument()"
                                        :color="color.theme"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn dark color="error" @click.stop="modalFromdatedocument = false">{{
                                          $t("myoutboxPage.conditionOptional.closefromdatedocument")
                                        }}</v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>

                                  <!-- ช่องกรอก เอกสารลงวันที่ -->
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-dialog
                                      ref="dialogTodatedocument"
                                      v-model="modalTodatedocument"
                                      :return-value.sync="todatedocument"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          readonly
                                          :color="color.theme"
                                          :label="$t('myoutboxPage.conditionOptional.todatedocument')"
                                          v-model="todatedocument"
                                          prepend-icon="event"
                                          @click:prepend="modalTodatedocument = true"
                                          v-on="on"
                                          outlined
                                          dense
                                          clearable
                                          :persistent-hint="todate_hint"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="todatedocument"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="$refs.dialogTodatedocument.save(todatedocument)"
                                        :min="fromdatedocument"
                                        :color="color.theme"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn dark color="error" @click.stop="modalTodatedocument = false">{{
                                          $t("myoutboxPage.conditionOptional.closetodatedocument")
                                        }}</v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellertaxid"
                                      :label="$t('myoutboxPage.conditionOptional.sellertaxid')"
                                      prepend-icon="mdi-domain"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellerbranchid"
                                      :label="$t('myoutboxPage.conditionOptional.sellerbranchid')"
                                      prepend-icon="mdi-format-list-bulleted-square"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyertaxid"
                                      :label="$t('myoutboxPage.conditionOptional.buyertaxid')"
                                      prepend-icon="mdi-domain"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm6 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyerbranchid"
                                      :label="$t('myoutboxPage.conditionOptional.buyerbranchid')"
                                      prepend-icon="mdi-format-list-bulleted-square"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
                <v-flex xs12 lg12 class="text-center pa-2">
                  <v-btn
                    :color="color.theme"
                    :text-color="color.chipText"
                    class="white--text text-center"
                    @click="fn_search_inbox()"
                    >{{$t('toolbar.searching.button')}}</v-btn
                  >
                </v-flex>
              </v-layout>
              <v-layout row wrap align-center justify-center v-else>
                <v-flex lg12 xs12 class="mr-4 ml-4">
                  <v-expansion-panels v-model="panel" multiple outlined>
                    <v-expansion-panel class="pa-1">
                      <v-expansion-panel-header>
                        <span :style="headerPage">{{ $t("dcmPage.conditionFile") }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-1">
                        <v-form>
                          <v-layout row wrap align-center justify-center>
                            <v-flex xs12 sm12 md12 lg12>
                              <v-container fluid>
                                <v-layout row wrap align-center justify-center>
                                  <v-flex xs6 sm6 md3 lg3>
                                    <v-select
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-6 pb-0"
                                      v-model="sortby"
                                      :items="listdsortby"
                                      :label="$t('resend.sorrt_by')"
                                      prepend-icon="mdi-sort"
                                      :color="color.theme"
                                      :item-color="color.theme"
                                    ></v-select>
                                  </v-flex>
                                  <v-flex xs6 sm6 md3 lg3>
                                    <v-select
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-6 pb-0"
                                      v-model="sendstatus"
                                      :items="listsendstatus"
                                      :label="$t('myinboxPage.conditionOptional.status')"
                                      prepend-icon="format_list_numbered"
                                      :color="color.theme"
                                      :item-color="color.theme"
                                    ></v-select>
                                  </v-flex>
                                  <v-flex xs2 sm2 md2 lg2 >
                                    <v-btn
                                      block
                                      :color="color.theme"
                                      :text-color="color.chipText"
                                      class=" pa-0 pl-2 pt-0 pb-0 white--text "
                                      @click="fn_search_inbox_summary()"
                                      >{{$t('toolbar.searching.button')}}</v-btn
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-6">
                  <v-layout row wrap class="pl-3 pr-3">
                    <v-flex xs2 lg2>
                      <v-select
                        prepend-inner-icon="sort"
                        hide-details
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-left"
                      >
                        <template v-slot:item="{ item }">
                          <div class="text-left">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }">
                          <div class="text-left">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex xs2 lg2>
                      <v-select
                        hide-details
                        solo
                        dense
                        v-model="type_doc_resend"
                        :items="listtype_doc_resend"
                        class="text-left pl-2"
                        @change="fn_checktypedocresend()"
                      ></v-select>
                    </v-flex>
                     <v-flex xs8 lg8 class="text-right">
                      <v-chip class="ma-2" color="primary" dark>
                        {{$t('myinboxPage.search_') + " " +  totalfile + " " + $t('myinboxPage.items')}}
                      </v-chip>
                    </v-flex>
                    <v-spacer></v-spacer>
                  </v-layout>
                  <v-layout>
                    <v-flex xs4 lg4>
                      <div class="pt-2">
                        <v-btn
                          fab
                          small
                          :color="color.theme"
                          :text-color="color.chipText"
                          class="white--text ma-1"
                          @click="multiresend()"
                        >
                          <v-icon>send</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs8 lg8 class="text-right">
                      <v-chip class="ma-2" color="error" dark>
                        {{ $t('resend.status_error') + " " + count_status_error }}
                      </v-chip>
                      <v-chip class="ma-2" color="#fc9208e6" dark>
                        {{ $t('resend.status_pending') + " " + count_status_pending }}
                      </v-chip>
                      <v-chip class="ma-2" color="success" dark>
                        {{ $t('resend.status_success') + " " + count_status_success }}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                  <br />
                  <!-- โชว์ข้อมูลไฟล์ -->
                  <div v-if="resolutionScreen <= 500">
                    <v-list two-line class="mb-6 pb-6 elevation-0" v-if="rootfile.length !== 0">
                      <v-list-item v-for="item in rootfile" :key="item.title" style="cursor: pointer;">
                        <v-list-item-avatar>
                          <v-checkbox v-model="filemultipleresend" :value="item" v-if="item.status_send !== 'SUCCESS'"/>
                        </v-list-item-avatar>
                        <v-list-item-avatar>
                          <div>
                            <v-icon x-large color="primary" style="font-weight: 100">file</v-icon>
                          </div>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.filename"></v-list-item-title>
                          <v-list-item-subtitle v-if="type_doc_resend === 'รายการไฟล์'">เลขที่เอกสาร : {{item.document_no}} </v-list-item-subtitle>
                           <v-list-item-subtitle><v-chip x-small :color="item.status_send === 'SUCCESS' ? 'success' : item.status_send === 'PENDING' ? '#fc9208e6' : 'error' " label text-color="white">{{item.status_send}}</v-chip></v-list-item-subtitle>
                        </v-list-item-content>
                         <v-list-item-action>
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-btn class="elevation-0" fab small v-on="on">
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="opendialogdetail = true ,newdata = item">
                              <v-list-item-icon>
                                <v-icon>mdi-information</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('myinboxPage.Filedetails')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-list v-else class="elevation-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('tablefile.empty_resend')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div v-else>
                    <v-data-table
                      v-if="type_doc_resend === 'รายการไฟล์'"
                      v-model="filemultipleresend"
                      :headers="headers"
                      :items="rootfile"
                      item-key="id"
                      :search="search"
                      class="elevation-1"
                      hide-default-footer
                      :single-select="sendstatus !== 'SUCCESS' ? false : true"
                      :show-select="true"
                      :page.sync="page"
                      :items-per-page="size"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:[`header.data_type`]="{ header }">
                        <span :style="headerTable">{{ header.text }}</span>
                      </template>
                      <template v-slot:[`header.filename`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.document_no`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.dtm_send`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.document_type`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.sender_name_th`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.receiver_name_th`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.last_dtm`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.status_send`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <!-- <template v-slot:[`header.id`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                    </template>

                      <template v-slot:[`header.remark`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template> -->

                      <template v-slot:item="props">
                        <tr>
                          <!-- <td width="1%" class="text-left"></td> -->
                          <td width="1%" class="text-left">
                            <v-checkbox v-model="filemultipleresend" :value="props.item" v-if="props.item.status_send !== 'SUCCESS'" />
                          </td>
                          <td width="15%" class="text-left" :title="props.item.filename">{{ props.item.filename | subStr }}</td>
                          <td width="5%" class="text-left">{{ props.item.document_no }}</td>
                          <td width="5%" class="text-left">{{ props.item.dtm_send === '00000000000000' ? '-' : formatdatetime(props.item.dtm_send) }}</td>
                          <td width="10%" class="text-left">{{ props.item.document_type }}</td>
                          <td width="7%" class="text-left">{{ props.item.sender_name_th }}</td>
                          <td width="7%" class="text-left">{{ props.item.receiver_name_th }}</td>
                          <td width="10%" class="text-left">{{ props.item.last_dtm === '00000000000000' ? '-' : formatdatetime(props.item.last_dtm) }}</td>
                          <td width="10%" class="text-left"><v-chip class="ma-2" :color="props.item.status_send === 'SUCCESS' ? 'success' : props.item.status_send === 'PENDING' ? '#fc9208e6' : 'error' " label text-color="white">{{ props.item.status_send }}</v-chip></td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-data-table
                      v-else
                      v-model="filemultipleresend"
                      :headers="headerssummary"
                      :items="rootfile"
                      item-key="id"
                      :search="search"
                      class="elevation-1"
                      hide-default-footer
                      :single-select="sendstatus !== 'SUCCESS' ? false : true"
                      :show-select="true"
                      :page.sync="page"
                      :items-per-page="size"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:[`header.filename`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.dtm_send`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.last_dtm`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.status_send`]="{ header }">
                        <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:item="props">
                        <tr>
                          <!-- <td width="1%" class="text-left"></td> -->
                          <td width="1%" class="text-left">
                            <v-checkbox v-model="filemultipleresend" :value="props.item" v-if="props.item.status_send !== 'SUCCESS'" />
                          </td>
                          <td width="25%" class="text-left" :title="props.item.filename">{{ props.item.filename | subStr_ }}</td>
                          <td width="25%" class="text-left">{{ props.item.dtm_send === '00000000000000' ? '-' : formatdatetime(props.item.dtm_send) }}</td>
                          <td width="25%" class="text-left">{{ props.item.last_dtm === '00000000000000' ? '-' : formatdatetime(props.item.last_dtm) }}</td>
                          <td width="25%" class="text-left"><v-chip class="ma-2" :color="props.item.status_send === 'SUCCESS' ? 'success' : props.item.status_send === 'PENDING' ? '#fc9208e6' : 'error' " label text-color="white">{{ props.item.status_send }}</v-chip></td>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                  <div class="text-xs-center">
                    <br />
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
      </v-card>
    </v-dialog>
    <multiresend
      :show="opendialogmultiresend"
      @closedialog="opendialogmultiresend = false,filemultipleresend =[], filedata = []"
      @closedialogreload="(opendialogmultiresend = false), getforward_inbox_extension(),filemultipleresend=[], filedata = []"
      :file="filedata"
      :taxid="tax_id"
      :type_data="type_doc_resend"
    />
    <dialogdetail
    :show="opendialogdetail"
    @closedkeydetail="opendialogdetail = false"
    @closedialogreload="opendialogdetail = false"
    :file="newdata"
    ></dialogdetail>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const multiresend = () => import("@/components/optional/dialog-multiresend");
const dialogdetail = () => import("@/components/optional/dialog-resendfiledetail");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "taxid_biz", "biz_id"],
  data() {
    return {
      listfile:[],
      sortby_data:"",
      listdsortby_data:["ไม่เรียง","ชื่อไฟล์ มากไปน้อย","ชื่อไฟล์ น้อยไปมาก","เอกสารลงวันที่ มากไปน้อย","เอกสารลงวันที่ น้อยไปมาก","วันที่ส่งเอกสาร มากไปน้อย","วันที่ส่งเอกสาร น้อยไปมาก"],
      newdata:'',
      opendialogdetail:false,
      fromdate_hint: "",
      todate_hint: "",
      file_namedoc: "",
      documentid: "",
      documentidto: "",
      document_type_code: "",
      sellertaxid: "",
      sellerbranchid: "",
      receiver_name:"",
      sender: "",
      buyertaxid: "",
      buyerbranchid: "",
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      fromdatedocument: "",
      modalFromdatedocument: false,
      todatedocument: "",
      modalTodatedocument: false,
      listsendstatus_sum: [],
      loaddataprogress: false,
      list_document_type: [],
      type_doc_resend: "รายการไฟล์",
      listtype_doc_resend: ["รายการไฟล์", "รวมรายการไฟล์"],
      count_status_error: 0,
      count_status_pending: 0,
      count_status_success: 0,
      // count_status_timeout: 0,
      listdocument_type_code: [],
      listsendstatus: [],
      listsender: [],
      panel: [0],
      listdsortby: ["ใหม่ไปเก่า", "เก่าไปใหม่"],
      sortby: "ใหม่ไปเก่า",
      sendstatus: "ERROR",
      tax_id: "",
      opendialogmultiresend: false,
      filedata: [],
      totalfile: 0,
      search: "",
      filemultipleresend: [],
      statusmutipleresend: false,
      singleSelect: true,
      notifications: "",
      sound: "",
      widgets: "",
      // count_file: 0,
      rootfile: [],
      page: 1,
      offset: 0,
      count: 0,
      size: 50,
      listsize: [50, 100, 200, 500],
      headers: [
        {
          text: "tablefile.filenameonly",
          lign: "left",
          value: "filename",
          sortable: false,
          width: "20%",
        },
        {
          text: "tablefile.docno",
          align: "left",
          value: "document_no",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.docdate",
          align: "left",
          value: "dtm_send",
          sortable: false,
          width: "15%",
        },
        {
          text: "tablefile.doctype",
          value: "document_type",
          align: "left",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.sendername_",
          value: "sender_name_th",
          align: "left",
          sortable: false,
          width: "15%",
        },
        {
          text: "tablefile.receivername_",
          value: "receiver_name_th",
          align: "left",
          sortable: false,
          width: "15%",
        },

        {
          text: "tablefile.outboxdate",
          value: "last_dtm",
          align: "left",
          sortable: false,
          width: "15%",
        },
        {
          text: "tablefile.status",
          value: "status_send",
          align: "left",
          sortable: false,
          width: "10%",
        },
      ],
      headerssummary: [
         {
          text: "tablefile.filenameonly",
          lign: "left",
          value: "filename",
          sortable: false,
          width: "40%",
        },
        {
          text: "tablefile.docdate",
          align: "left",
          value: "dtm_send",
          sortable: false,
          width: "20%",
        },
        {
          text: "tablefile.outboxdate",
          value: "last_dtm",
          align: "left",
          sortable: false,
          width: "20%",
        },
        {
          text: "tablefile.status",
          value: "status_send",
          align: "left",
          sortable: false,
          width: "20%",
        },
      ]
    };
  },
  components: { multiresend ,dialogdetail},
  
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageMax",
      "dataStorageUsage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.totalfile;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },

    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    shower: {
      get() {
        if (this.show === true) {
          // this.fn_getfile();
          console.log('nnnnnn', this.taxid_biz);
          this.getforward_inbox_extension();
          // this.fn_search_inbox();
        }
          return this.show;

      },
    },
  },

  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
    subStr_: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 60) return string.substring(0, 60) + "...";
        else return string;
      }
    },
  },
  methods: {
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = format(new Date(), "yyyy-MM-dd");
    },
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      this.todatedocument = format(new Date(), "yyyy-MM-dd");
    },
    fn_checktypedocresend(){
       if(this.type_doc_resend === 'รายการไฟล์'){
        this.getforward_inbox_extension();
      }else{
        this.fn_search_inbox_summary();
      }
    },
    clickpagination() {
      if(this.type_doc_resend === 'รายการไฟล์'){
        this.fn_search_inbox();
      }else{
        this.fn_search_inbox_summary();
      }
    },
    multiresend() {
      if(this.filemultipleresend.length != 0){
      console.log("this.filemultipleresend", this.filemultipleresend);
        for(let i = 0 ; i < this.filemultipleresend.length; i++ ){
          if(this.filemultipleresend[i].status_send !== 'SUCCESS'){
            this.filedata.push(this.filemultipleresend[i])
          }
        }
        this.tax_id = this.taxid_biz;
        this.opendialogmultiresend = true;
      }
    },
    clearmultipledownload() {
      this.filemultipleresend.splice(0, this.filemultipleresend.length);
    },
    checktotalfile() {
      if (this.totalfile === 0) {
        return true;
      } else {
        return false;
      }
    },
    setformatdate(_date) {
      if (_date === "" || _date === null || _date === undefined) {
        return "-";
      } else {
        let data = _date.substring(8, 6) + "/" + _date.substring(6, 4) + "/" + _date.substring(0, 4);
        return data || "-";
      }
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === null || _datetime === undefined) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },
    seticon(parameter) {
      console.log("parameter", parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    closedialog() {
      this.$emit("closedialog");
    },
    async getforward_inbox_extension() {
      this.loaddataprogress = true;
      this.list_document_type = [];
      let payload = {
        business_id: this.biz_id,
      };
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get/forward_inbox_extension", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("Response new API ", response);
            // this.count_file = response.data.total
            this.listsendstatus = response.data.send_status;
            for (let i = 0; i < response.data.document_type.length; i++) {
              this.list_document_type.push(response.data.document_type[i]);
            }
            this.listdocument_type_code = this.list_document_type;
            this.loaddataprogress = false;
            if(this.type_doc_resend === 'รายการไฟล์'){
              this.fn_search_inbox();
            }else{
              this.fn_search_inbox_summary();
            }
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log("New Loaddata Error:", error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    async fn_search_inbox() {
      this.loaddataprogress = true;
      this.rootfile = [];
      this.totalfile = 0;
      console.log(this.sender);
      this.loaddataprogress = true;
      let offset = this.size * (this.page - 1);
      console.log("offset",offset);
      let payload = {
        business_id: this.biz_id,
        // limit:'20',
        // offset:'0',
        limit: this.size.toString(),
        offset: offset.toString(),
        filename: this.file_namedoc,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.fromdatedocument,
        doc_date_end: this.todatedocument,
        document_type_code: this.document_type_code,
        cre_dtm_start: this.fromdateupload,
        cre_dtm_end: this.todateupload,
        sender_name: this.sender,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receiver_name,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status_send: this.sendstatus,
        order_by: "DESC",
        // order_by: this.sortby === "ใหม่ไปเก่่า" ? "DESC" : "ASC",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get/forward_inbox", payload,{headers: { Authorization: auth.code }} )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("Response new API ", response.data);
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFile = {};
              dataFile["count_send"] = response.data.data[i].count_send;
              dataFile["document_no"] = response.data.data[i].document_date;
              dataFile["document_type"] = response.data.data[i].document_type;
              dataFile["document_type_code"] = response.data.data[i].document_type_code;
              dataFile["dtm_send"] = response.data.data[i].dtm_send;
              dataFile["file_pdf"] = response.data.data[i].file_pdf;
              dataFile["file_xml"] = response.data.data[i].file_xml;
              dataFile["filename"] = response.data.data[i].filename;
              dataFile["id"] = response.data.data[i].id;
              dataFile["last_dtm"] = response.data.data[i].last_dtm;
              dataFile["receiver_branch_id"] = response.data.data[i].receiver_branch_id;
              dataFile["receiver_name_eng"] = response.data.data[i].receiver_name_eng;
              dataFile["receiver_name_th"] = response.data.data[i].receiver_name_th;
              dataFile["receiver_taxid"] = response.data.data[i].receiver_taxid;
              dataFile["sender_branch_id"] = response.data.data[i].sender_branch_id;
              dataFile["sender_name_eng"] = response.data.data[i].sender_name_eng;
              dataFile["sender_name_th"] = response.data.data[i].sender_name_th;
              dataFile["sender_taxid"] = response.data.data[i].sender_taxid;
              dataFile["status_send"] = response.data.data[i].status_send;

              this.rootfile.push(dataFile);
            }
            this.count_status_error = response.data.error;
            this.count_status_pending = response.data.pending;
            this.count_status_success = response.data.success;
            
            this.totalfile = response.data.total;
            console.log("Newrootfile(My file)", this.rootfile);
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log("New Loaddata Error:", error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    async fn_search_inbox_summary() {
      this.loaddataprogress = true;
      this.totalfile = 0;
      // this.count_file = 0;
      this.rootfile = [];
      console.log(this.sender);
      this.loaddataprogress = true;
      let payload = {
        business_id: this.biz_id,
        order_by: this.sortby === "ใหม่ไปเก่า" ? "DESC" : "ASC",
        status_send: this.sendstatus,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get/forward_inbox_summary", payload,{headers: { Authorization: auth.code }})
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("Response new API ", response.data);
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFile = {};
              dataFile["count_send"] = response.data.data[i].count_send;
              dataFile["dtm_send"] = response.data.data[i].dtm_send;
              dataFile["filename"] = response.data.data[i].filename;
              dataFile["id"] = response.data.data[i].id;
              dataFile["last_dtm"] = response.data.data[i].last_dtm;
              dataFile["status_send"] = response.data.data[i].status_send;

              this.rootfile.push(dataFile);
            }

            this.count_status_error = response.data.error;
            this.count_status_pending = response.data.pending;
            this.count_status_success = response.data.success;
            // this.count_file = response.data.total;
            this.totalfile = response.data.total;
            console.log("Newrootfile(My file)", this.rootfile);
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log("New Loaddata Error:", error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
  },
};
</script>

<style>
/* #thiscontainer {
  height: calc(50vh - 90px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
} */
</style>
